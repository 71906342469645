import React, { useEffect, useState } from "react";
import constate from "constate";
import Cookies from "js-cookie";
import {
  OwnershipDict,
  ProductOwnershipData,
} from "functions/src/shared/Digital/ProductOwnership";
import { useUser } from "../../Firebase/SDK/firebaseAuth";
import {
  getAnalytics,
  setUserId as analyticsSetUserId,
} from "firebase/analytics";
function useUserHook() {
  const user = useUser();
  const [emailVerified, setEmailVerified] = useState(
    user ? user.emailVerified : false
  );
  useEffect(() => {
    if (user) {
      setEmailVerified(user.emailVerified);
    }
  }, [user]);

  //email 驗過，或是用其他provider登入
  const IsAccountValid =
    emailVerified ||
    (user &&
      user.providerData.length > 0 &&
      user.providerData[0].providerId != "password");

  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [ageCheckPassed, setAgeCheckPassed] = useState(false);
  const [authRequiredPath, setAuthRequiredPath] = useState<string>(null);
  // const [ownCurrentProduct, setOwnCurrentProdcut] = useState(false); //TODO: 這個寫法很怪，
  const [user_id, setUserId] = useState(null);
  //ownership在這邊記著，沒有就撈
  const [ownershipDict, setOwnershipDict] = useState<OwnershipDict>({});
  // function HasOwnershipCheck(uuid: string) {
  //   return ownershipDict[uuid] != null;
  // }

  const [policyAgreed, setPolicyAgreed] = useState<boolean | undefined>(
    undefined
  );
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAdult, setIsAdult] = useState<boolean>(undefined);
  const IsAdultCheck = (birthtime: number) => {
    console.log("birthdate", new Date(birthtime));

    var age = new Date(Date.now() - birthtime).getFullYear() - 1970;
    console.log("Age", age);
    if (age >= 18) {
      setIsAdult(true);
      return true;
    }
    return false;
  };
  const checkAdultCookie = () => {
    if (isAdult == true) return;
    var birthdaystr = Cookies.get("birthday");
    var birthtime = parseInt(birthdaystr);
    IsAdultCheck(birthtime);
  };
  React.useEffect(() => {
    checkAdultCookie();
    const analytics = getAnalytics();
    analyticsSetUserId(analytics, user_id);

    // setUserProperties(analytics, { country: });
  }, []);

  return {
    isLoginLoading,
    setIsLoginLoading,
    isAdult,
    setIsAdult,
    authRequiredPath,
    setAuthRequiredPath,
    policyAgreed,
    setPolicyAgreed,
    IsAdultCheck,
    ownershipDict,
    setOwnershipDict,
    user_id,
    setUserId,
    isAdmin,
    setIsAdmin,
    emailVerified,
    IsAccountValid,
    setEmailVerified,
  };
}

export const [UserContextProvider, useUserContext] = constate(useUserHook);
