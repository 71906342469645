import constate from "constate";
import {
  CheckoutTranslateStoryblok,
  CommonTranslateStoryblok,
  MyLibraryTranslateStoryblok,
  ProjectTranslateStoryblok,
  SignUpDoneTranslateStoryblok,
} from "functions/src/shared";
import { useRouter } from "next/router";
type TranslateBlok =
  | Record<string, string>
  | CommonTranslateStoryblok
  | ProjectTranslateStoryblok
  | CheckoutTranslateStoryblok
  | MyLibraryTranslateStoryblok
  | SignUpDoneTranslateStoryblok;

type TranslateNamespace =
  | "project"
  | "myLibrary"
  | "checkout"
  | "signIn"
  | "common";
function useStoryblokTranslate({
  // projectTranslate,
  // myLibrayTranslate,
  // checkoutTranslate,
  // signInTranslate,
  // commonTranslate,
  translatePackage,
}: // commonTranslate,
{
  // projectTranslate?: ProjectTranslateStoryblok; //所有都optional, 有需要才傳過來
  // myLibrayTranslate?: ProjectTranslateStoryblok;
  // checkoutTranslate?: CheckoutTranslateStoryblok;
  // signInTranslate?: Record<string, string>;
  // commonTranslate?: CommonTranslateStoryblok;

  translatePackage: Record<string, TranslateBlok>;
}) {
  // const [_commonTranslate, setCommonTranslate] =
  //   React.useState<CommonTranslateStoryblok>(commonTranslate);
  //dict state
  const dict = translatePackage ?? {};
  //todo:用state來控制好像會太慢...不確定該怎麼做 每次都蓋掉?
  // useEffect(() => {
  //   console.log("New Package", translatePackage, router.pathname);

  //   setDict({ ...dict, ...translatePackage });
  // }, [translatePackage]);
  // const [dict, setDict] = React.useState<Record<string, TranslateBlok>>(
  //   translatePackage ?? {}
  // );

  // function installTranslation(
  //   namespace: TranslateNamespace,
  //   translate: Record<string, string>
  // ) {
  //   // Object.assign(dict[namespace], translate);
  //   // dict[namespace] = translate;
  //   // setDict(dict);
  //   setDict({ ...dict, [namespace]: translate });
  //   //setPersonInfo(prevPersonInfo => ({...prevPersonInfo, firstName: "name"}))
  // }
  const router = useRouter();

  // 1. getStaticProps fetch here?
  // 2. runtime install...
  const projectTranslate = dict[
    "projectpagetranslate"
  ] as ProjectTranslateStoryblok;
  const checkoutTranslate = dict[
    "checkout-translate"
  ] as CheckoutTranslateStoryblok;

  const myLibraryTranslate = dict[
    "my-library-translate"
  ] as MyLibraryTranslateStoryblok;

  const signInTranslate = dict[
    "signupdonetranslate"
  ] as SignUpDoneTranslateStoryblok;
  // console.log("[translate dict]", dict);

  return {
    myLibraryTranslate,
    checkoutTranslate,
    signInTranslate,
    projectTranslate,
    dict,
  };
}
export function useStoryblokTranslation(namespace: TranslateNamespace) {
  const { dict } = useStoryblokTranslateContext();
  function t(term_key: string) {
    return dict[namespace]?.[term_key];
  }
  return { t };
}
export const [TranslateProvider, useStoryblokTranslateContext] = constate(
  useStoryblokTranslate
);
