import { useRouter } from "next/router";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function TranslateWrapper(props: {
  children: React.ReactNode;
  ns: string;
}) {
  //TODO: array
  //   const ns = "devotion";
  const { ns } = props;
  const router = useRouter();

  const [loaded, setLoaded] = React.useState(false);
  //   console.log(router.query.l);
  const { i18n } = useTranslation(ns);
  //TODO:把這層包出去
  // const [lang, setLang] = React.useState(router.query.l);
  // lang context...?
  const queryLang = router.query.l as string;
  //TODO: 參數??
  const LoadLanguage = async () => {
    // console.log(i18n.language);
    try {
      const data = await import(
        "../../locales/" + router.locale + `/${ns}.json`
      );
      i18n.addResourceBundle(router.locale, ns, data);
      // console.log(data);
      // console.log(i18next);
      if (i18n.hasResourceBundle(router.locale, ns)) {
        //有嗎?
        //FIXME: change language after loaded...
        i18n.changeLanguage(router.locale);
        setLoaded(true);
      }
    } catch {
      //Default English
      const data = await import("../../locales/" + "en-US" + `/${ns}.json`);
      i18n.addResourceBundle("en-US", ns, data);
      // i18n.changeLanguage("en-US");
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    // console.log("update translate wrapper");
    // langContext.langInit();

    //強迫使用某個語言
    if (queryLang) {
      //TODO: 要把這個一直pass下去，還是要改cookie...?
      //只改當前頁面語言，切走就切掉
      // i18n.changeLanguage(queryLang);
      //改cookie
      // langContext.ChangeLanguege(queryLang);
    }

    if (i18n.hasResourceBundle(router.locale, ns)) {
      i18n.changeLanguage(router.locale);
      setLoaded(true);
    } else {
      LoadLanguage();
    }

    // console.log(lang);
    // console.log(router.locale);
    // console.log(router.defaultLocale);
    // if (lang != queryLang) {
    //   console.log("query lang" + queryLang);
    //   router.replace(router.asPath, router.asPath, { locale: queryLang });
    // } else if (lang != navigator.language) {
    //   console.log("query lang" + navigator.language);
    //   router.replace(router.asPath, router.asPath, { locale: queryLang });
    // }
    //   console.log(router);
    //   console.log(router.pathname);
    //   if (lang == null) {
    //     setLang(navigator.language);
    //     console.log("Nvaigator lang" + navigator.language);
    //   }
  }, [loaded, router.locale]);
  // if (loaded == false) {
  //   return <div>...</div>;
  // } else return <div>{props.children}</div>;
  return <div>{props.children}</div>;
}
