import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en-US",
    // fallbackLng: "en-US",
    fallbackLng: {
      "en-US": ["en"],
      "zh-TW": ["zh"],
      "ko-KR": ["ko"],
      "ja-JP": ["ja"],
      // 'es': ['fr'],
      default: ["en-US"],
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
