import { Theme } from "@mui/material";
import constate from "constate";
import React from "react";
import { mainColor } from "./Theme";
// import { mainColor } from "./Theme";
function useRCTheme({ defaultTheme }: { defaultTheme: Theme }) {
  const [showFooter, setShowFooter] = React.useState<boolean>(true);
  const [showNavButtons, setShowNavButtons] = React.useState<boolean>(true);
  // const [appBarColor, setAppBarColor] = React.useState<
  //   PropTypes.Color | "transparent"
  // >("primary");
  const [appBarColor, setAppBarColor] = React.useState<string>(
    defaultTheme.palette.primary.main
    // defaultTheme.palette.background.default
  );
  const [theme, setTheme] = React.useState<Theme>(defaultTheme);

  return {
    theme,
    setTheme,
    showFooter,
    setShowFooter,
    appBarColor,
    setAppBarColor,
    showNavButtons,
    setShowNavButtons,
  };
}

export const [RCThemContextProvider, useRCThemeContext] = constate(useRCTheme);

//change App Bar color
export function useChangeAppBarColor(
  color: string,
  showFooter: boolean = true
) {
  const { setAppBarColor, setShowNavButtons, setShowFooter } =
    useRCThemeContext();

  React.useEffect(() => {
    setShowFooter(showFooter);
    setAppBarColor(color);
    setShowNavButtons(false);
    return () => {
      setAppBarColor(mainColor);
      setShowNavButtons(true);
      setShowFooter(true);
    };
  }, []);
}
