import StoryblokClient, {
  StoriesParams,
  StoryParams,
} from "storyblok-js-client";

export const storyblokClient = new StoryblokClient({
  accessToken: "TQD1fgbQvMOrwrRgr37TBQtt",
  cache: {
    clear: "auto",
    type: "memory",
  },
});
export class StoryBlokConfig {
  StoryblokConfig() {
    this.version = "draft";
    console.log("[version]: init", this.version);
  }
  version: "draft" | "published";
  ChangeVersion(v: "draft" | "published") {
    this.version = v;
    console.log("[version]:", this.version);
  }
}
export const storyblokConfig = new StoryBlokConfig();
export function sbParams(): StoryParams {
  return {
    version: storyblokConfig.version,
    fallback_lang: "default",
  };
  // resolve_relations: "Game.product",
  // resolve_links: "story",
}

export function sbsParams(): StoriesParams {
  return { version: storyblokConfig.version, fallback_lang: "default" };

  // find_by: "uuid",
  // resolve_links: "story",
}
export interface FetchStoryblokParam {
  locale: string;
  full_slug?: string;
  uuid?: string; //single... multiple?
  uuids?: string[];
  filter?: {
    // component?: string;
    fieldName?: string;
    fieldValue?: any;
  };
  starts_with?: string;
  resolve_relation?: {
    component: string;
    field: string;
  }[];
  resolve_links?: "url" | "story" | "0" | "1";
  with_tag?: string;
  link?: boolean;
  published?: boolean;
}
