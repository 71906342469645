import { useRouter } from "next/router";
import React, { useEffect } from "react";
import {
  AlertProvider,
  SnackBarSingleton,
} from "../src/Alert/SnackBarProvider";

import "../src/i18n";
import TranslateWrapper from "../src/i18n/TranslateWrapper";

import {
  RCThemContextProvider,
  useRCThemeContext,
} from "../src/Theme/ThemeContext";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
// import { ThemeProvider } from "@mui/styles";
import { redCandleTheme } from "../src/Theme/Theme";
import { CssBaseline } from "@mui/material";
import { CacheProvider, EmotionCache } from "@emotion/react";
//https://nextjs.org/docs/advanced-features/custom-app
import "../styles/globals.css";
import FirebaseWrapperNext from "../src/_firebaseWrapper";
// const FirebaseWrapperNext = dynamic(() => import("../src/_firebaseWrapper"), {
//   ssr: false,
// });
// import getConfig from "next/config";
import createEmotionCache from "../src/createEmotionCache";
import { AppProps } from "next/dist/shared/lib/router/router";

// Client-side cache, shared for the whole session of the user in the browser.
// Initialize the Storyblok JS Bridge

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
// import NavBar from "../src/Nav/NavBar";
// import Footer from "../src/Basic/Footer";
import dynamic from "next/dynamic";
import { TranslateProvider } from "../src/i18n/useStoryblokTranslate";
import { useSmDown } from "../src/Nav/useDownSM";
// const locale = pageProps
const Footer = dynamic(() => import("../src/Basic/Footer"), {
  ssr: false,
});
const NavBar = dynamic(() => import("../src/Nav/NavBar"), {
  ssr: false,
});
function Appp({ Component, pageProps }: { Component; pageProps }) {
  const { showFooter, theme } = useRCThemeContext();
  // if (pageProps.translate) {
  //   console.log("Common Translate Here!", pageProps.translate);
  // } else {
  //   console.log("Common Translate Not Here QQ!");
  // }
  const router = useRouter();

  // useEffect(() => {
  //   import("react-facebook-pixel")
  //     .then((x) => x.default)
  //     .then((ReactPixel) => {
  //       ReactPixel.init("980187298724164"); // facebookPixelId
  //       ReactPixel.pageView();

  //       router.events.on("routeChangeComplete", () => {
  //         ReactPixel.pageView();
  //       });
  //     });
  // }, [router.events]);
  // useEffect(() => {
  //   // const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  //   // const options = {
  //   //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   //   debug: false, // enable logs
  //   // };

  // }, []);
  const smDown = useSmDown();
  return (
    // <div>
    <TranslateProvider translatePackage={pageProps.translate}>
      <FirebaseWrapperNext>
        {/* <Head></Head> */}
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AlertProvider>
              <SnackBarSingleton></SnackBarSingleton>

              <TranslateWrapper ns="common">
                <NavBar></NavBar>
              </TranslateWrapper>

              {/* {config.url.mode == "localDev" && (
        
        )} */}
              {!smDown && (
                <style jsx global>
                  {`
                    body {
                      scrollbar-width: thin;
                    }

                    ::-webkit-scrollbar {
                      width: 12px;
                      height: 12px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                      background: transparent;
                      padding: 2px;
                      /* border-radius: 5px; */
                    }
                    ::-webkit-scrollbar-button {
                      /* background-color: #555; */
                      height: 0px;
                      width: 0px;
                    }
                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                      /* background: #e50e4066; */
                      background: #fff6;
                      border: 2px solid transparent;
                      border-radius: 5px;
                      background-clip: content-box;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                      /* border: 1px; */
                      border: 1px solid transparent;
                      /* background: #555; */
                    }
                    ::-webkit-scrollbar-button:single-button {
                      background-color: rgb(64, 64, 64);

                      display: block;
                      background-size: 10px;
                      background-repeat: no-repeat;
                    }

                    ::-webkit-scrollbar-button:horizontal:decrement {
                      border-width: 7px 14px 7px 0;
                      border-color: transparent #404040 transparent transparent;
                    }

                    ::-webkit-scrollbar-button:horizontal:decrement:hover {
                      border-color: transparent #505050 transparent transparent;
                    }

                    /* Right */
                    ::-webkit-scrollbar-button:horizontal:increment {
                      border-width: 7px 0 7px 14px;
                      border-color: transparent transparent transparent #404040;
                    }

                    ::-webkit-scrollbar-button:horizontal:increment:hover {
                      border-color: transparent transparent transparent #505050;
                    }
                  `}
                </style>
              )}

              <div style={{ background: theme.palette.background.default }}>
                <div
                  style={{
                    minHeight: "calc(100vh - 240px)",
                    paddingTop: smDown ? 56 : 0,
                  }}
                >
                  {/* <MaintainPage></MaintainPage> */}
                  <Component {...pageProps} />
                </div>
              </div>
              {/* </div> */}
              {showFooter && <Footer></Footer>}
            </AlertProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </FirebaseWrapperNext>
    </TranslateProvider>
  );
}
export default function MyApp({
  emotionCache = clientSideEmotionCache,
  Component,
  pageProps,
}: MyAppProps) {
  const router = useRouter();

  useEffect(() => {
    // console.log("process.env.VERCEL_ENV", env);
    // Router.events.on("routeChangeStart", (v) => {
    //   // Cancel routeChange event by erroring
    //   // See https://github.com/zeit/next.js/issues/2476
    //   // Router.events.emit("routeChangeError");
    //   console.warn("Routing", v);
    //   // throw `routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.`;
    // });
    console.log("Router", router.pathname);
  }, []);
  return (
    <CacheProvider value={emotionCache}>
      <RCThemContextProvider defaultTheme={redCandleTheme}>
        {/* <RCThemContextProvider defaultTheme={nineSolsTheme}> */}
        {/* <TranslateProvider commonTranslate={pageProps.commonTranslate}> */}
        <Appp Component={Component} pageProps={pageProps}></Appp>
        {/* </TranslateProvider> */}
      </RCThemContextProvider>
    </CacheProvider>
  );
}
