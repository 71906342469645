import dynamic from "next/dynamic";
// import "firebase/analytics";
import * as React from "react";
import { CheckOutProvider } from "./Digital/CheckOut/CheckOutContext";
import { OrderProvider } from "./Digital/CheckOut/OrderContext";
import { firebaseApp } from "./Firebase/SDK/firebase";
import FirebaseInit from "./Firebase/serverConfig";
import { LanguageProvider } from "./i18n/LanguageContext";
import { UserContextProvider } from "./User/Auth/UserContext";
// import { getApp } from "firebase/app";
// import { firebaseApp } from "./Firebase/SDK/firebase";
// import AuthCheckLoading from "./Utility/Route/AuthCheckLoading";
const AuthCheckLoading = dynamic(
  () => import("./Utility/Route/AuthCheckLoading"),
  {
    ssr: false,
  }
);
// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const UserLocationWrapper = dynamic(
  () => import("./i18n/UserLocationWrapper"),
  {
    ssr: false,
  }
);
function Content(props) {
  // const firebaseApp = getApp();

  React.useEffect(() => {
    console.log("FirebaseWrapper");
  }, []);

  return (
    <FirebaseInit>
      {/* <AuthProvider sdk={auth}> */}
      <UserContextProvider>
        <LanguageProvider>
          <OrderProvider>
            <CheckOutProvider>
              {props.children}
              <AuthCheckLoading fallback={null}>
                <UserLocationWrapper></UserLocationWrapper>
              </AuthCheckLoading>
            </CheckOutProvider>
          </OrderProvider>
        </LanguageProvider>
      </UserContextProvider>
      {/* </AuthProvider> */}
    </FirebaseInit>
  );
}
export default function FirebaseWrapperNext(props) {
  // React.useEffect(() => {
  React.useEffect(() => {
    const _app = firebaseApp; // initializeApp(config.firebaseConfig);
  }, []);
  // }, []);
  return (
    // <FirebaseAppProvider
    //   firebaseConfig={config.firebaseConfig}
    //   firebaseApp={app}
    // >
    <Content>{props.children}</Content>
    // </FirebaseAppProvider>
  );
}
