import { useEffect, useState } from "react";
// import * as React from "react";
import constate from "constate";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { HttpsCallableResult } from "firebase/functions";
import Cookies from "js-cookie";
import { useFunc } from "../Firebase/useFunc";
// import "firebase/functions";
// function getCookie(name) {
//   console.log("Cookie" + document.cookie);

//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   console.log("GetC", parts);

//   if (parts.length === 2) return parts.pop().split(";").shift();
// }
const translatedDict = {
  "zh-TW": true,
  "zh-CN": true,
  "ko-KR": true,
  "ja-JP": true,
  "en-US": true,
  ja: "ja-JP",
  ko: "ko-KR",
  zh: "zh-TW",
  en: "en-US",
};
export function getUpperCaseLocale(lang: string) {
  try {
    if (lang.length > 2) {
      var splited = lang.split("-");
      const text = splited[0] + "-" + splited[1].toUpperCase();
      if (translatedDict[text]) return text;
      else return "en-US";
    } else {
      //兩字
      if (translatedDict[lang]) {
        return translatedDict[lang];
      } else return "en-US";
    }
  } catch (e) {
    return "en-US";
  }
}
const priceLabelDict = {
  TWD: "NT$",
  USD: "US$",
};
function upperCaseNavigatorLanguage() {
  if (typeof window !== "undefined") {
    return getUpperCaseLocale(navigator.language);
  } else return "en-US";
}
function useLanguage() {
  const { i18n } = useTranslation();
  const router = useRouter();
  var savedCountry = Cookies.get("country");
  const [inited, setInited] = useState(false);
  const [navLang, setNavLang] = useState(router.locale);
  // const [country, setCountry] = useState(savedCountry || "US");
  const [country, setCountry] = useState("US");
  const [currency, setCurrency] = useState<"USD" | "TWD">(
    navLang == "zh-TW" ? "TWD" : "USD"
  );
  console.log("currency", currency);
  const priceLabel = currency == "TWD" ? "NT$" : "US$";
  // const [priceLabel, setPriceLabel] = useState("US$");
  const userLocationCallable = useFunc("userLocation");
  useEffect(() => {
    console.log("UseEffect Lang", router.locale);

    i18n.changeLanguage(router.locale);
    // setNavLang(upperCaseNavigatorLanguage());
  }, []);
  const checkCountry = (country: string) => {
    if (country == "TW") {
      setCurrency("TWD");
      // setPriceLabel("NT$");
      console.log("Currency", "TWD");
    } else {
      setCurrency("USD");
      // setPriceLabel("US$");
      console.log("Currency", "USD");
    }
    console.log("Check Country", country);
    Cookies.set("country", country);
    setCountry(country);
  };
  // useEffect(() => {
  //   langInit();
  // }, []);
  const langInit = () => {
    if (inited) {
      return;
    }
    console.log("LangInit");

    const cookieLang = Cookies.get("NEXT_LOCALE");

    //1. 用cookie
    if (cookieLang != undefined) {
      const lng = getUpperCaseLocale(cookieLang);
      console.log("Cookie NEXT_LOCALE:" + cookieLang, lng);
      if (lng != navLang) ChangeLanguege(lng);
    }
    //2. 用navigator
    else {
      if (router.locale == "en-US") {
        const lng = getUpperCaseLocale(navigator.language);
        console.log("Use Navigator:" + navigator.language, lng);
        if (lng != navLang) ChangeLanguege(lng);
      }
    }
    detectCurrency();
    setInited(true);
  };
  const detectCurrency = () => {
    console.log("[Detect Currency Start]");
    var savedCountry = Cookies.get("country");
    //FIXME: 每次都先重拿
    // savedCountry = undefined;

    console.log("[Cookies] Country", savedCountry);
    const forceUS = false;
    const forceTW = false;
    if (forceUS) {
      checkCountry("US");
    } else if (forceTW) {
      checkCountry("TW");
    } else if (savedCountry) {
      checkCountry(savedCountry);
    } else {
      //WARNING: 有cookie就會沒有call 到
      userLocationCallable().then(
        (resp: HttpsCallableResult<{ country: string }>) => {
          const serverCountry = resp.data.country;
          console.log("[Server Detect Country]", resp.data);

          if (serverCountry) {
            Cookies.set("country", serverCountry);
            checkCountry(serverCountry);
          } else {
            //default US?
            console.log("[Country:Default]");

            Cookies.set("country", "US");
            checkCountry("US");
          }
        }
      );
    }
  };
  //顯示目前和偏好設定分開做
  //?l=...才是目前
  const ChangeLanguege = (lng: string) => {
    if (router.route != "/_error") {
      //TODO: 沒有該語言...?
      document.cookie = "NEXT_LOCALE=" + lng + ";path=/";
      console.log("Set Lang", lng);
      setNavLang(lng);
      // i18n.changeLanguage(lng);
      console.log("Change Lang Router", router);
      // debugger;
      // router.query = {};
      if (router.pathname == "/")
        router.replace(router.pathname, router.pathname, { locale: lng });
      else router.replace(router.asPath, router.asPath, { locale: lng });
      // debugger;
    }
    // debugger;
  };
  const RoundPrice = (price: number) => {
    if (currency == "TWD") return price.toFixed(0);
    else if (currency == "USD") return price.toFixed(2);
  };
  return {
    ChangeLanguege,
    langInit,
    currency,
    RoundPrice,
    priceLabel,
    country,
    checkCountry,
    detectCurrency,
    navLang,
  };
}
export const [LanguageProvider, useLanguageContext] = constate(useLanguage);
