// import { getAnalytics } from "firebase/analytics";
import dynamic from "next/dynamic";
import * as React from "react";
import { config } from "../clientConfig";
import NoSSR from "../Utility/NoSSR";
import FirebaseAnalytics from "./FirebaseAnalytics";
// import { firebaseApp } from "./SDK/firebase";

const LocalServer = dynamic(() => import("./LocalServer"), {
  ssr: false,
});
export default function FirebaseInit(props: { children: React.ReactNode }) {
  const mode =
    config.payment.serverMode == "localDev" ? "localEmulator" : "online";
  // console.log(mode);
  // React.useEffect(() => {
  //   getAnalytics(firebaseApp);
  // }, []);
  return (
    <div>
      {mode == "localEmulator" && (
        // <Suspense fallback={<div>loading</div>}>
        <LocalServer>{props.children}</LocalServer>
        // </Suspense>
      )}
      {mode == "online" && <div>{props.children}</div>}
      <NoSSR>
        <FirebaseAnalytics></FirebaseAnalytics>
      </NoSSR>
    </div>
  );
}
