import { createTheme } from "@mui/material/styles";
//E01747
// export const mainColor = "#c6173c"; // main: "#e50e40",
export const mainColor = "#E01747";
// export const darkMainColor = "#21040a";
export const secondaryTextColor = "#918F8D";
export const discountBackground = "rgb(15 147 107)";
export const discountColor = "#D2F5FF";
export const redCandleTheme = createTheme({
  typography: {
    fontFamily:
      "Lato,-apple-system,segoe ui,微軟正黑體,Malgun Gothic,Meiryo,microsoft jhenghei,sans-serif",
    h1: {
      fontSize: "3rem",
    },
  },

  palette: {
    mode: "dark",
    primary: {
      // backgroundColor: "#E01747"
      main: "#E01747",
      // main: "#c6173c",
      contrastText: "#fff",
      // dark: "#fff",
      // dark: "#21040a"
    },
    // divider: "#ADA59A",
    // secondary: {
    //   main: "#ADA59A",
    // },
    background: {
      // default: "#1a1b1e", //靛藍灰
      // default: "#420814", //深紅
      // default: "rgb(26, 32, 39)", //灰藍

      // default: "#161C21",
      default: "#070C11",
    },
    text: {
      primary: "#fff3e1",
      // secondary: secondaryTextColor,
      secondary: "#ADA59A",
      disabled: "#66625C",
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: "#15171a", //靛藍灰
          // backgroundColor: "#101419",
          // "24292d"
          // backgroundColor: "#121315",
          // backgroundColor: "#101113",

          // backgroundColor: "#161C21", //中
          backgroundColor: "#070C11", //下
        },
        // elevation: {
        //   backgroundColor: "#171D22",
        // },
        // elevation0: {
        //   backgroundColor: "070C11",
        // },
        // elevation1: {
        //   backgroundColor: "#171D22",
        // },
        // elevation: {
        //   backgroundColor: "#161C21",
        // },
        elevation1: {
          backgroundColor: "#161C21",
          // backgroundColor: "#24292D",
          backgroundImage: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          borderRadius: 1,
          height: 3,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 1,
      },
    },

    MuiTypography: {
      // defaultProps: {
      // },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          fontFamily: "Noto Sans TC, sans-serif;",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 6,
          marginBottom: 6,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        standard: {
          marginTop: 6,
          marginBottom: 6,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#fff",
          },
        },
      },
    },
    MuiTab: {},
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        // color:"textPrimary",
      },
      // Name of the styleSheet
      styleOverrides: {
        root: {
          // minWidth: 0,
          fontWeight: 400,
          borderRadius: 4,
          // border: 0,
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            color: "#fff",
            // backgroundColor: "#404245",
          },
          textTransform: "none",
        },

        textPrimary: {
          //Button variant text, color primary
          //nav bar 的button...不知道為甚麼
          color: "#fff3e1",
          "&:hover": {
            // color: "#fff",
            backgroundColor: "#404248aa",
          },
        },
        outlined: {
          // color: "#ffffffdd", //style={{ color: "#febaba" }}
          // borderColor: "#ffffffdd",
          "&:hover": {
            // fontWeight: "bold",
            // color: "#fff",
            borderColor: "#fff",
          },
        },
        // outlinedPrimary: {
        //   color: "#ffffffdd", //style={{ color: "#febaba" }}

        //   "&:hover": {
        //     // fontWeight: "bold",
        //     color: "#fff",
        //   },
        // },
        containedPrimary: {
          // color: "#fff", //style={{ color: "#febaba" }}
          // "&:hover": {
          //   // fontWeight: "bold",
          //   color: "#fff",
          // },
        },
      },
    },
  },

  // props: {
  //   MuiGrid: {
  //     spacing: 1,
  //   },
  //   MuiSelect: {
  //     variant: "outlined",
  //   },
  //   MuiTextField: {
  //     variant: "outlined",
  //     size: "small",
  //   },
  //   MuiButton: {
  //     disableRipple: true,
  //     disableElevation: true,
  //   },
  //   MuiTab: {
  //     disableRipple: true,
  //   },
  // },
});
