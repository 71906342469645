import { getFunctions, httpsCallable } from "firebase/functions";

export function useFunc<TRequest = any, TResponse = any>(functionName: string) {
  const functions = getFunctions();
  const ecpayTradeTokenCallable = httpsCallable<TRequest, TResponse>(
    functions,
    functionName
  );
  return ecpayTradeTokenCallable;
}
