import React, { useEffect, useState } from "react";
import constate from "constate";
import { config } from "../../clientConfig";

import {
  BillingInfo,
  PaymentGateWay,
  PaymentReqData,
} from "functions/src/shared/Digital/Payment";
import { useLanguageContext } from "../../i18n/LanguageContext";
import { useRouter } from "next/router";
import { useBuyerData, useReceiptID, useShippingData } from "./OrderContext";

import { useProjectContext } from "../Product/Project/ProjectContext/ProjectContext";
import { ProductStoryData } from "functions/src/shared/Digital/ProductStoryData";
import {
  BundleStoryData,
  PledgeOptionStoryData,
  ProductEntryStoryblok,
  ProjectStoryData,
} from "functions/src/shared";
import { ShippingInfo } from "functions/src/shared/Digital/User/PaymentInfo";
import { CheckoutBuyerInfoFormData } from "./CheckoutForm/CheckoutBuyerInfoFormData";
// import { ProductObject, ProductData, ProductDict } from "functions/lib/shared";
//寫死的資料
//TODO:要這麼長嗎...
// const records: Record<string, ProductData> = {
//   devotion_physical_taiwan_edition_with_OST_bundle: {
//     price: 1280,
//     description: "還願台灣限定實體精裝版 + 遊戲原聲帶 同捆包",
//     title: "還願台灣限定實體精裝版 + 遊戲原聲帶 同捆包"
//   },
//   devotion_physical_taiwan_edition: {
//     price: 990,
//     description: "還願台灣限定實體精裝版",
//     title: "還願台灣限定實體精裝版"
//   }
// };
export type ProductEntry = { product: ProductStoryData; num: number };
function useCheckout() {
  // const ref = useDatabase().ref("exchangeRate");
  // const exchangeRateData = useDatabaseObject(ref);
  // useEffect(() => {
  //   console.log("exchangeRate:", exchangeRateData.data);
  // }, [exchangeRateData.data]);

  const [TWD_to_USDRate, setTWD_to_USDRate] = useState(0.03595504450586525);
  //信用卡資訊合法
  const [bundle, setBundle] = useState<BundleStoryData>();
  const [pledgeOptionID, setPledgeOptionID] = useState<string>();
  // const [pledgeOption, setPledgeOption] = useState<PledgeOptionDocData>();
  // const [pidList, setPidList] = useState<string[]>([]); //現在是從docs來的...
  const [productEntries, setProductEntries] = useState<
    ProductEntry[]
    // Pick<ProductEntryStoryblok, "link" | "num">[]
  >([]);
  const [paymentGateWay, setPaymentGateWay] = useState<PaymentGateWay>();
  const [checkoutSignedData, setCheckoutSignedData] = useState<any>();
  const [tradeToken, setTradeToken] = useState("");
  const [microform, setMicroForm] = useState<any>();
  const [flex, setFlex] = useState<any>();
  const [cardType, setCardType] = useState("");
  const [orderID, setOrderID] = useState("");
  // const [cardNumber, setCardNumber] = useState("");
  // const [cardExpiry, setCardExpiry] = useState("");
  // const [cardCVN, setCardCVN] = useState();
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [currentTradeRefId, setCurrentTradeRefID] = useState("");
  // const [exp, setExp] = useState<{ expMonth: string; expYear: string }>({
  //   expMonth: "01",
  //   expYear: "20",
  // });
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [checkOutState, setCheckOutState] = React.useState<
    "filling" | "signing" | "confirming" | "pending" | "purchased" | "fail"
  >("filling");
  // React.useEffect(() => {
  //   const pids = docs.reduce((pV: string[], cV) => {
  //     pV.push(cV.uuid);
  //     return pV; // *********  Important ******
  //   }, []);
  // }, [docs]);
  const isCheckoutValid = () => {
    return (
      // expMonth != "" &&
      // expYear != "" &&
      // cardNumberValid &&
      // securityCodeValid &&
      checkOutState == "filling"
    );
  };
  const lngCtx = useLanguageContext();
  const router = useRouter();
  // const buyerData = useBuyerData();
  // const receiptID = useReceiptID();
  // const shippingData = useShippingData();
  // const auth = useAuth();

  //把資料送到後端整理成一包
  const paymentReqData = ({
    uid,
    gateway,
    project,
    billingData,
    shippingData,
  }: {
    uid: string;
    gateway: PaymentGateWay;
    billingData?: CheckoutBuyerInfoFormData;
    shippingData?: ShippingInfo;
    project?: {
      projectData: ProjectStoryData;
      pledgeOptionData: PledgeOptionStoryData;
      bonus: number;
    };
  }) => {
    let reqData: PaymentReqData = {
      campaign_slug: project && project.projectData.slug,
      slug: "",
      uid: uid,
      //沒有bundle的話就null
      bundle_slug: bundle && bundle.slug,
      gateway: gateway,
      currency: lngCtx.currency,
      productEntries: productEntries.map((entry) => {
        return {
          slug: entry.product.slug,
          uuid: entry.product.uuid,
          num: entry.num,
        };
      }),
      // uuids: products,
      // pids: pidList,
      token: "", //不需要token
      // expMonth: checkoutCtx.expMonth,
      // expYear: "20" + checkoutCtx.expYear,

      locale: router.locale.toLowerCase(),
    };

    //實體運送資料
    if (shippingData) {
      reqData.shipping = shippingData;
    }
    if (billingData) {
      const { receiptID, ..._billing } = billingData;
      reqData.billing = {
        country: lngCtx.country,
        ..._billing,
        taiwan_customer_identifier: receiptID, //統編
      };
    }

    //第一個product的slug
    if (productEntries.length > 0) {
      reqData.slug = productEntries[0].product.slug;
    }

    if (bundle) {
      reqData.slug = bundle.slug;
    }

    //如果是募資專案
    if (project) {
      const { projectData, pledgeOptionData, bonus } = project;
      reqData.backProject = {
        project_slug: projectData.slug,
        pledge_option_uuid: pledgeOptionData.uuid,
        pledge_option_slug: pledgeOptionData.slug,
        bonus: bonus,
      };

      reqData.slug = pledgeOptionData.slug;
      if (pledgeOptionData.content.is_shipping) {
        reqData.shipping = shippingData;
        console.log("Shipping For paypal", reqData.shipping);
      }

      if (reqData.backProject.bonus != null)
        if (reqData.backProject.bonus < 0) reqData.backProject.bonus = 0;
    }

    //募資專案
    console.log("ReqData From Checkout Ctx", reqData);

    return reqData;
  };
  //打資料給cybersource
  const checkoutSendData = ({ cardNumber, expiryDate, cvc }) => {
    const data = checkoutSignedData;
    const ref_id = data.reference_number;
    setCurrentTradeRefID(ref_id);

    const expiry = expiryDate.replace(/\s/g, "").split("/");

    //打過去cybersource
    const payData = {
      ...data,
      card_type: cardType,
      card_number: cardNumber.replace(/\s/g, ""),
      card_expiry_date: expiry[0] + "-20" + expiry[1],
      card_cvn: cvc,
    };
    console.log("PayData", payData);

    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("target", "_blank");
    form.setAttribute("action", config.payment.secureAcceptanceURL);
    for (var field in payData) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", field);
      hiddenField.setAttribute("value", payData[field]);
      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    console.log(form);

    form.submit();
    setCheckOutState("pending");
  };
  const Clear = () => {
    setCheckOutState("filling");
    // setPidList([]);
    setBundle(null);
    // setDocs([]);
    setProductEntries([]);
    setCardType(undefined);
  };
  // const rate = { USD: 1.128161, TWD: 31.376988 };
  function exchangeUSDFromTWD(twd_price: number) {
    //ctx???
    return twd_price * TWD_to_USDRate;
  }

  function FormatPriceString(amount: number, exchangeFromTWD = false) {
    // const currency = lngCtx.currency;
    // if (currency == "USD") {
    //   var usdAmount = amount;
    //   if (exchangeFromTWD) {
    //     usdAmount = exchangeUSDFromTWD(amount);
    //   }
    //   return "US$ " + usdAmount.toFixed(2);
    // }

    // if (currency == "TWD") return "NT$ " + amount.toFixed(0);

    // return "NT$ " + amount.toFixed(0);
    amount = Math.round(amount);
    return "NT$ " + amount.toLocaleString();
  }

  // const [list, setProductList] = useState<ProductObject[]>(products);
  //   const records = ProductDict.itemData;
  //   const [record] = useState<Record<string, ProductData>>(records);
  //   const list = () => {
  //     return Object.keys(records).map((key) => {
  //       const pObj: ProductObject = { id: key, val: records[key] };
  //       return pObj;
  //     });
  //   };
  return {
    checkoutSendData,
    setCheckoutSignedData,
    // pidList,
    bundle,
    setBundle,
    checkOutState,
    setCheckOutState,
    productEntries,
    setProductEntries,
    tradeToken,
    setTradeToken,
    expMonth,
    setExpMonth,
    expYear,
    setExpYear,
    cardType,
    setCardType,
    microform,
    setMicroForm,
    flex,
    setFlex,
    pledgeOptionID,
    setPledgeOptionID,
    agreementChecked,
    setAgreementChecked,
    // setCardNumberValid,
    // setSecurityCodeValid,
    isCheckoutValid,
    Clear,
    currentTradeRefId,
    setCurrentTradeRefID,
    paymentReqData,
    TWD_to_USDRate,
    setTWD_to_USDRate,
    FormatPriceString,
    paymentGateWay,
    setPaymentGateWay,
    orderID,
    setOrderID,
  };
}

export const [CheckOutProvider, useCheckOutContext] = constate(useCheckout);
