//前端定義dev, Prod 參數，deploy時要先切換，
//TODO:可能可以改成下環境變數???

import { storyblokConfig } from "functions/src/shared/Digital/Storyblok/storyblokClient";
import getConfig from "next/config";
interface ClientConfig {
  paynow: {
    WebNo: string;
    CheckoutURL: string;
  };
  paypal: {
    "client-id": string;
  };
  serverMode: "localDev" | "dev" | "prod";
  secureAcceptanceURL: string;
  fb_pixel_id: string;
}
const localDevConfig: ClientConfig = {
  paynow: {
    WebNo: "N126178827",
    CheckoutURL: "https://test.paynow.com.tw/service/etopm.aspx",
  },
  paypal: {
    "client-id":
      "ATefUUCzbSEZR6uTVU6ezc6OY_d05SgFwg9x9dDYo7iPGYcK2Zpo4y2K94GmTe12df-wMf-Bb-FifFUB",
  },
  serverMode: "localDev",
  // secureAcceptanceURL: "https://secureacceptance.cybersource.com/silent/pay",
  secureAcceptanceURL:
    "https://testsecureacceptance.cybersource.com/silent/pay",
  fb_pixel_id: "335914123705812", //詹易衡的帳號
};
const devlopmentConfig: ClientConfig = {
  paynow: {
    WebNo: "F128200620",
    CheckoutURL: "https://test.paynow.com.tw/service/etopm.aspx",
  },
  paypal: {
    "client-id":
      "ATefUUCzbSEZR6uTVU6ezc6OY_d05SgFwg9x9dDYo7iPGYcK2Zpo4y2K94GmTe12df-wMf-Bb-FifFUB",
  },
  serverMode: "dev",
  // secureAcceptanceURL: "https://secureacceptance.cybersource.com/silent/pay",
  secureAcceptanceURL:
    "https://testsecureacceptance.cybersource.com/silent/pay",
  fb_pixel_id: "335914123705812",
};
const prodConfig: ClientConfig = {
  paynow: {
    WebNo: "42640160",
    CheckoutURL: "https://www.paynow.com.tw/service/etopm.aspx",
  },
  serverMode: "prod",
  secureAcceptanceURL: "https://secureacceptance.cybersource.com/silent/pay",
  paypal: {
    "client-id":
      "AYLWUDj03fVJc4Gf6zWvpAXCpnLtlS9yaQKXq3NQXqzNATut_Z-sZ_jO4aZ_aENvu9fSmcZIROfxLnr1",
  },
  fb_pixel_id: "980187298724164", //赤燭/王瀚宇的帳號
};

//firebase 測試用參數
const devFirebaseConfig = {
  apiKey: "AIzaSyCC6IHpAkf1RxlbQHVE-9IKoHXSioC4jFA",
  authDomain: "redcandle-dev.firebaseapp.com",
  databaseURL: "https://redcandle-dev-default-rtdb.firebaseio.com/",
  projectId: "redcandle-dev",
  storageBucket: "redcandle-dev.appspot.com",
  messagingSenderId: "740579405471",
  appId: "1:740579405471:web:1e3dbf9de520ef4d323caa",
  measurementId: "G-6R6R291RJC",
};

//firebase 上線版參數
const prodFirebaseConfig = {
  apiKey: "AIzaSyCKzmxHRyn6RkPcPe_J9WQVoLweTL-1WuI",
  authDomain: "devotion-sale.firebaseapp.com",
  databaseURL: "https://devotion-sale.firebaseio.com",
  projectId: "devotion-sale",
  storageBucket: "devotion-sale.appspot.com",
  messagingSenderId: "57630150209",
  appId: "1:57630150209:web:99e05ea25fd3e7ecb600c0",
  measurementId: "G-18CL11ZQCS",
};

//NOTE: 切換環境
var _config = { payment: localDevConfig, firebaseConfig: devFirebaseConfig };

const { publicRuntimeConfig } = getConfig();
const isForcePublished = false; //storyblok的版本

// export var { vercel_env } = { vercel_env: "production" }; //測試用 改資料時直接接上去
export var {
  vercel_env,
}: {
  vercel_env: "local" | "development" | "preview" | "production"; //preview=staging
} = publicRuntimeConfig;

console.log("vercel_env config", vercel_env);

if (vercel_env == "local") {
  console.log("Env:LocalDev");
  _config = { payment: localDevConfig, firebaseConfig: devFirebaseConfig };
  if (isForcePublished) storyblokConfig.ChangeVersion("published");
  else storyblokConfig.ChangeVersion("draft");
  //_config = { url: previewConfig, firebaseConfig: devFirebaseConfig };
} else if (vercel_env == "development") {
  _config = { payment: devlopmentConfig, firebaseConfig: devFirebaseConfig };
  storyblokConfig.ChangeVersion("draft");
} else if (vercel_env == "preview") {
  //
  console.log("Env:Dev");
  // _config = { payment: previewConfig, firebaseConfig: devFirebaseConfig };
  _config = { payment: prodConfig, firebaseConfig: prodFirebaseConfig };
  storyblokConfig.ChangeVersion("published");
} else if (vercel_env == "production") {
  console.log("Env:Prod");
  _config = { payment: prodConfig, firebaseConfig: prodFirebaseConfig };
  storyblokConfig.ChangeVersion("published");
}
// console.log("ENV", process.env);
// _config = { payment: prodConfig, firebaseConfig: prodFirebaseConfig };
export const config = _config;
