import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp } from "./firebase";
console.log("initializeAuth");

let inited = false;

function init() {
  const _auth = initializeAuth(firebaseApp, {
    persistence: [
      indexedDBLocalPersistence,
      browserLocalPersistence,
      browserSessionPersistence,
    ],
  });
  inited = true;
  return _auth;
}
let _auth: Auth;
const _getAuth = () => {
  if (inited) {
    return _auth;
  } else {
    _auth = init();
    return _auth;
  }
};
export const auth = _getAuth();

// export const auth = getAuth(firebaseApp);
export function useUser() {
  const [user, loading, error] = useAuthState(auth);
  return user;
}
