import * as React from "react";
import { Snackbar, Button } from "@mui/material";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import constate from "constate";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export function SnackBarSingleton() {
  //     const [open,setOpen] = {}
  //   return (
  // console.log("snakbar render");
  //   );
  const alertControl = useAlertContext();
  // const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    alertControl.setOpen(false);
  };

  return (
    <div>
      {/* <Button
        onClick={() => {
          alertControl.show("test");
        }}
      >
        show
      </Button> */}
      <Snackbar
        key="share"
        open={alertControl.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        // message="I love snacks"
      >
        <Alert onClose={handleClose} severity={alertControl.severity}>
          {alertControl.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function useAlert() {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<AlertColor>("success");
  const [message, setMessage] = React.useState("讚");
  const [adultContentWarningDisplay, setAdultContentWarningDisplay] =
    React.useState(true);
  const show = (msg: string, sever: AlertColor = "info") => {
    // console.log("Show SnackBar..", msg);
    setSeverity(sever);
    setMessage(msg);
    setOpen(true);
  };
  return {
    open,
    setOpen,
    message,
    setMessage,
    show,
    severity,
    setSeverity,
    adultContentWarningDisplay,
    setAdultContentWarningDisplay,
  };
}
export const [AlertProvider, useAlertContext] = constate(useAlert);
