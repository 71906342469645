import { useEffect, useState } from "react";
import constate from "constate";
//import { ProductDict } from "functions/lib/shared";
import {
  CheckoutBuyerInfoFormData,
  CheckoutShippingInfoFormData,
} from "./CheckoutForm/CheckoutBuyerInfoFormData";
import { ShippingInfo } from "functions/src/shared/Digital/User/PaymentInfo";
import { useLanguageContext } from "../../i18n/LanguageContext";

function useOrder() {
  // const { t } = useTranslation("checkout");
  // const [paid, setPaid] = useState(false);
  // const [orderNum, setOrder] = useState(record);
  const lngCtx = useLanguageContext();

  const [receiptType, setReceiptType] = useState<"B2B" | "B2C">("B2C");
  const [receiptID, setReceiptID] = useState("");
  // const [paymentType, setPaymentType] = useState<"CREDIT" | "ATM">("CREDIT");
  // const isLocal = window.location.hostname === "localhost";
  //cardholder;
  // console.log("isLocal", isLocal);
  //FIXME: 如何runtime才拿window.location.hostname? 這段會在generate static page時就跑了，要用什麼包？

  const isLocal = false;
  const [buyerData, setBuyerData] = useState<CheckoutBuyerInfoFormData>(
    isLocal
      ? {
          email: "",
          lastName: "",
          firstName: "",
          phone: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          postal_code: "",
          cardNumber: "4000000000000002",
          expiryDate: "12/29",
          cvc: "123",
        }
      : {
          email: "",
          lastName: "",
          firstName: "",
          phone: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          postal_code: "",
          cardNumber: "",
          expiryDate: "",
          cvc: "",
        }
  );

  const [shippingData, setShippingData] = useState<ShippingInfo>({
    lastName: "",
    firstName: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    country: lngCtx.country,
  });

  console.log(
    "ShippingDefault Order country",
    lngCtx.country,
    shippingData.country
  );

  // console.log("Context shipping", shippingData.state, shippingData);

  //receiptID valid
  const Clear = () => {
    // setPaid(false);
    // setOrder({});
    setBuyerData({
      email: "",
      lastName: "",
      firstName: "",
      phone: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      postal_code: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    });
    console.log("ResetData");
    setReceiptType("B2C");
    setReceiptID("");
  };
  // const setItem = (product_id: string, count: number) => {
  //   if (count === 0) {
  //     delete orderNum[product_id];
  //   } else {
  //     orderNum[product_id] = count;
  //   }
  //   console.log(orderNum);

  //   //FIXME: 複製一份? for render?
  //   setOrder({ ...orderNum });
  // };
  // const calculateItemTotalAmount = () => {
  //   const productIDs = Object.keys(orderNum);
  //   var totalProduct = 0;
  //   productIDs.forEach((key) => {
  //     let num = orderNum[key];
  //     let price = ProductDict.itemData[key].price;
  //     totalProduct += num * price;
  //   });
  //   return totalProduct;
  // };
  // const getTotalNum = () => {
  //   var totalNum = 0;
  //   for (const key in orderNum) {
  //     let num = orderNum[key];
  //     if (num < 0) {
  //       return false;
  //     }
  //     if (num > 0) {
  //       totalNum += num;
  //     }
  //   }
  //   return totalNum;
  // };
  // const isOrderValid = (maxOrderNum: number) => {
  //   // const keys = Object.keys(orderNum);
  //   const totalNum = getTotalNum();
  //   if (totalNum == 0 || totalNum > maxOrderNum) return false;
  //   return true;
  // };

  // const getOrderProductIDs = () => {
  //   return Object.keys(orderNum);
  // };

  // const getTradeInfo = () => {
  //   return {
  //     orderNum: orderNum,
  //     paymentType: paymentType!,
  //     receiptID,
  //     receiptType
  //   };
  // };

  // const getPaymentTypeText = () => {
  //   if (paymentType === "ATM") return "ATM轉帳";
  //   else if (paymentType === "CREDIT") return "信用卡";
  //   else return "";
  // };

  return {
    receiptType,
    setReceiptType,
    receiptID,
    setReceiptID,
    buyerData,
    setBuyerData,
    shippingData,
    setShippingData,
    // calculateItemTotalAmount,
    resetData: Clear,
  };
}
//所有function 拆掉
export const [
  OrderProvider,
  useReceiptType,
  useSetReceiptType,
  useReceiptID,
  useSetReceiptID,
  useBuyerData,
  useSetBuyerData,
  useShippingData,
  useSetShippingData,
  useResetOrderData,
] = constate(
  useOrder,
  (value) => value.receiptType,
  (value) => value.setReceiptType,
  (value) => value.receiptID,
  (value) => value.setReceiptID,
  (value) => value.buyerData,
  (value) => value.setBuyerData,
  (value) => value.shippingData,
  (value) => value.setShippingData,
  (value) => value.resetData
);
