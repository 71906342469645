import { getAnalytics } from "firebase/analytics";
import React from "react";
import { firebaseApp } from "./SDK/firebase";

export default function FirebaseAnalytics() {
  React.useEffect(() => {
    getAnalytics(firebaseApp);
  }, []);
  return <div></div>;
}
