import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export function useSmDown(props: { noSsr?: boolean } = { noSsr: false }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: props.noSsr,
  });
  return matches;
}

export function useMdDown(props: { noSsr?: boolean } = { noSsr: false }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: props.noSsr,
  });
  return matches;
}
